<template>
  <div >
    <b-container>
      <b-row class="justify-content-md-center pb-5 pt-5">
        <b-col cols="12" class="text-center mb-5">
          <h1>Partner</h1>
        </b-col>
        <b-col sm="2" v-for="item in partners" :key="item._id">
          <b-img-lazy v-bind="mainProps" :src="url+'/'+item.image" :alt="item.title"></b-img-lazy>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>


<script>

import { mapActions } from 'vuex'

export default {
  data () {
    return {
      partners: [],
      url: process.env.VUE_APP_DOMAIN_IMAGE,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        width: 400,
        height: 400,
        class: 'my-3'
      }
    }
  },
  methods: {
    ...mapActions('website', ['getPartner']),
    getImageUrl(imageId) {
      const { width, height } = this.mainProps
      return `https://picsum.photos/${width}/${height}/?image=${imageId}`
    },
   
    async getData () {
      let res = await this.getPartner();

      if (res.success) {
        this.partners = res.data;
      }

    }
  },
  async mounted () {
    await this.getData();
  }
}
</script>
    