<template>
<div style="background: whitesmoke" class="pt-3 pb-3">
  <b-container class="my-8" >
    <div class="section-title">
      <b-link class="title-link">Podcast</b-link>
    </div>

    <b-row class="podcast-list">
      <b-col>
        <section>
            <vue-horizontal-list :items="podcasts" :options="options">
              <template v-slot:default="{item}">
                <div class="item">
                  <div class="podcast-item" @click="goTo(item._id)">
                    <figure class="podcast-image w-100 border" :style="{ backgroundImage: `url(${root_url}/${item.thumbnail})` }" />
                    <b-link class="podcast-link text-center">{{ item.name }}</b-link>
                    <!-- <div class="text-muted text-truncate">{{ item.name }}</div> -->
                  </div>
                </div>
              </template>
            </vue-horizontal-list>
          </section>
      </b-col>
   
    </b-row>

    <!-- Explore -->
    <div class="text-center">
      <!-- <b-button variant="primary" size="lg">Explore trending playlists</b-button> -->
    </div>
  </b-container>
</div>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list';

export default {
  components: {
    VueHorizontalList
  },
  data () {
    return {
      root_url: process.env.VUE_APP_DOMAIN_API + 'images/',
      options: {
          responsive: [
            {end: 576, size: 1}, 
            {start: 576, end: 768, size: 2},
            {start: 768, end: 992, size: 3},
            {size: 4}
          ],
          list: {
            // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
            windowed: 767,
            
            // Because: #app {padding: 80px 24px;}
            // padding: 24
          }
        },
    }
  },
  methods: {
    goTo ( link ) {
      this.$router.push(`./../media/${link}`);
    },
    imgUrl (img) {
      return require(`@/assets/images/homepage/podcast/${img}.jpg`)
    }
  },
  props: {
    podcasts: Array
  }
}
</script>

<style >
.vhl-item {
  width: 200px !important;
}
.vhl-btn-left, .vhl-btn-right {
  margin-top: -80px;
}

.vhl-list {
  /* direction: rtl; */
}
</style>