<template>
  <div class="my-5" >
    <div style="margin-top: 70px !important" class="bg-danger">
      <img v-if="banners.image != ''" :src='`${root_url}/${banners.image}`' alt="" width="100%">
    </div>

    <b-container class="my-5">

      <div>
          <h3 class="mb-1" >Podcast</h3>
          <div class="short--line"></div> <br>
      </div>

      <div>
        <b-row>
          <b-col md="12" >
            <b-form-select
              v-model="selected_podcast"
              :options="category"
              class="mb-3"
              @change="getData"
              value-field="_id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      
      <div class="text-center mt-8" v-if="media.length == 0">
        <h5 >-- ยังไม่มี Podcast ในหมวดนี้ -- </h5>
      </div>
  
      <b-row class="podcast-list">
        <b-col
          v-for="(item, index) in media"
          :key="'media'+index"
          md="4"
          lg="2"
        >
          <div class="podcast-item" @click="goToMedia( item._id )">
            <figure class="podcast-image border" :style="{ backgroundImage: `url(${root_url}/${item.thumbnail})` }" />
            <b-link class="podcast-link text-center">{{ item.name }}</b-link>
            <!-- <div class="text-muted text-truncate">{{ item.name }}</div> -->
          </div>
        </b-col>
      </b-row>

      <!-- Explore -->
      <!-- <div class="text-center">
        <b-button variant="light" size="lg"> <i class="fas fa-angle-down"></i> Load more</b-button>
      </div> -->
    </b-container>

  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  data () {
    return {
      selected_podcast: null,
      root_url: process.env.VUE_APP_DOMAIN_IMAGE,
      id: this.$route.params.cid,
    }
  },
  methods: {
    ...mapActions('website', ['getCategoryPage']),
    ...mapMutations('website', ['setBlogHead']),

    async allPodcast () {
      this.$router.push('podcast')
      await this.getData();
    },
   
    goToMedia(id) {
      this.$router.push(`./../media/${id}`);
    },
    async getData () {
      let cid = this.selected_podcast;

      let payload = {
        type: 'podcast'
      }

      if (cid != null) {
        payload.cate = cid;
      }

      

      console.log(payload);

      await this.getCategoryPage(payload)

      
    }
  },
  async mounted () {

    await this.getData();
    
  },
  computed: {
    ...mapState('website', ['banners', 'media', 'category', 'name_cate']),
    computed_head_name () {
      
      for (let i=0;i< this.category.length;i++) {
        console.log(this.category[i]._id, this.id);
        if (this.category[i]._id == this.id ) {
          return  this.category[i].name;
        }
      }
      
    }
  }
}
</script>


<style scoped>
.podcast--control--item {
  position: absolute;
  bottom: 0;
}
.next--podcast {
  padding: 11px;
}
.next--podcast:hover {
  background: #e4e4e4;
}
.nav--sub {
  color: #9e9e9e;
  background: #232323;
}
</style>
