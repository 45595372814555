
<template>
  <div class="my-5 pb-5" >

    <div style="margin-top: 70px !important" class="bg-danger">
    </div>

    <img :src="blog_banner" alt="" srcset="" width="100%">

    <b-container>
      <b-row>
        <b-col cols="12" class="mt-5 mb-5">
          <div>
            <h3 class="mb-1">{{blog_head}}</h3>
            <div class="short--line"></div> <br>
          </div>
        </b-col>
      
        <b-col v-if="blogs.length == 0">
          <div class="text-center">
            *** ยังไม่มีข้อมูลในหน้านี้ ***
          </div>
        </b-col>

        <b-col class="mb-4 pointer" md="3" v-for="(item, index) in blogs" :key="index">
    
          <b-card
            :title="item.title"
            :img-src="url+'/'+item.image"
            class="shadow"
            img-alt="Image"
            img-top 
            @click="viewBlog(item._id)">
            <b-card-text>
              <div class="txt--date">
                {{ item.created_at }}
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        
      </b-row>
    </b-container>

  </div>

</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      page_type: 'hot_issue',
      url: process.env.VUE_APP_DOMAIN_IMAGE
    }
  },
  methods: {
    ...mapActions('website', ['getBlogGroup']),
    ...mapMutations('website', ['setHeaderById']),
    async viewBlog (id) {
      this.$router.push('/blog/'+id)
    },
    async listBlog ( ) {

      let id = this.$route.params.id;
      
      this.$router.push( `/${this.page_type}/${id}` );


      await this.getBlogGroup({
        type: this.page_type,
        category: id
      });
    },
  },
  async mounted() {
    await this.listBlog();

    await this.setHeaderById({ type: this.page_type, id: this.$route.params.id})
  },
  computed: {
    ...mapState('website', ['blogs', 'blog_head', 'blog_banner'])
  }
}
</script>

<style scoped>
.txt--date {
  font-size: 13px;
  color: #aca9a9;
}
</style>      
