<template>
  <div id="background">
    <!-- <pre>{{ itemOfDay }}</pre> -->
    <b-container style="padding: 0">
      <b-row>
        <b-col cols="9" sm="6">
          <div id="background-container">
            <b-row class="text-white">
              <b-col cols="12" sm="3" lg="2" class="d-none d-sm-block"
                ><b-img
                :id="isPlayVideo ? `logo-mini` : ``"
                  class="all-button"
                  src="/img/icons/fm_icon.png"
                  height="80"
                ></b-img
              ></b-col>
              <b-col cols="12" sm="9" lg="10">
                <h5 v-if="itemOfDay.title !== 'ไม่มีรายการเวลานี้'">Thinking Radio</h5>

                <div v-if="itemOfDay.title === 'ไม่มีรายการเวลานี้'">
                  <h3 class="no-order" style="margin-top: 5%;">Thinking Radio</h3>
                </div>
                <div v-else class="marquee">
                  <p>{{ itemOfDay.title }}</p>
                </div>

                <p v-if="itemOfDay.title !== 'ไม่มีรายการเวลานี้'">
                  {{ itemOfDay.start_time }} - {{ itemOfDay.end_time }} น.
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3" sm="6" class="text-right">
          <videoPlayer
            style="display: none"
            ref="videoPlayer"
            class="vjs-custom-skin"
            :options="playerOptions"
            @play="onPlayerPlay($event)"
            @ready="onPlayerReady($event)"
          >
          </videoPlayer>

          <div class="all-button">

            <!-- <pre>{{ isPlayVideo }}</pre> -->
            <b-button
              v-if="!isPlayVideo"
              class="play-radio-button"
              style="background: transparent"
              @click="playVideo()"
              ><i class="lni lni-play icon-play"></i
            ></b-button>
            <b-button
              v-else
              class="play-radio-button"
              style="background: transparent"
              @click="stopVideo()"
              ><i class="lni lni-pause icon-pause"></i
            ></b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { videoPlayer } from "vue-videojs7";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "home",
  components: {
    videoPlayer,
  },
  data() {
    return {
      itemOfDay: {},
      isPlayVideo: false,
      radios: [],
      radioList: [],
      source: "",
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    ...mapActions("website", ["getRadio", "_getRadioList"]),

    liveSource(item) {
      this.source = item.link;
      this.getOrder();
    },
    onPlayerPlay(player) {

      this.isPlayVideo = true;

    },
    onPlayerReady(player) {

      this.isPlayVideo = false;

      // this.player.play();
    },
    playVideo() {
      this.isPlayVideo = true;

      const promise = this.player.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
          })
          .catch((error) => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            console.log('play error :>> ', error);
          });
      }
    },
    stopVideo() {
      this.isPlayVideo = false;

      this.player.pause();
    },
    getOrder() {
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: this.source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      this.player.load();

      // this.player.autoplay('muted'); //* this is not working
    },
    filterDay() {
      const thaiWeeks = [
        "วันอาทิตย์",
        "วันจันทร์",
        "วันอังคาร",
        "วันพุธ",
        "วันพฤหัสบดี",
        "วันศุกร์",
        "วันเสาร์",
      ];

      const day = moment().format("DD");
      const month = moment().format("MM");
      const year = moment().format("YYYY");

      const d = new Date();
      const dayIndex = d.getDay();

      const dayList = this.radioList[dayIndex];

      const datePettren = "YYYY-MM-DD HH:mm:ss";

      const items = dayList.items.filter((item) => {
        const dateStart = moment(
          `${year}-${month}-${day} ${item.start_time}`,
          datePettren
        );
        const dateEnd = moment(
          `${year}-${month}-${day} ${item.end_time}`,
          datePettren
        );

        if (dateStart <= d && dateEnd >= d) {
          return item;
        }
      });

      let objItem = {
        title: "ไม่มีรายการเวลานี้",
        start_time: "",
        end_time: "",
      };

      if (items.length > 0) {
        objItem = items[0];
        objItem["start_time"] = moment(
          objItem["start_time"],
          "HH:mm:ss"
        ).format("HH:mm");
        objItem["end_time"] = moment(objItem["end_time"], "HH:mm:ss").format(
          "HH:mm"
        );
      }

      this.itemOfDay = {
        day: thaiWeeks[dayIndex],
        ...objItem,
      };
    },
    async getRadioList() {
      let res = await this._getRadioList();

      if (res.success) {
        this.radioList = res.data;
        this.filterDay();
      }
    },
  },
  async mounted() {
    let res = await this.getRadio();

    if (res.success) {
      this.radios = res.data;
      if (res.data.length > 0) {
        this.source = res.data[0].link;
      }
    }

    await this.getRadioList();

    setInterval(async () => {
      await this.getRadioList();
    }, 60 * 1000); // 1 minute

    this.getOrder();
  },
  // beforeDestroy() {
  //   console.log("beforeDestroy");
  //   this.player.src('');
  //   this.player.load();
  // },
};
</script>

<style lang="css">
.player {
  position: absolute !important;
  left: 0;
  width: 100%;
  height: 400px;
}
.vjs-custom-skin {
  padding: 0px 12px;
  height: 400px !important;
  width: 100%;
}

.vjs-custom-skin /deep/ .video-js {
  height: 100%;
  max-height: 200px;
  width: 100%;
}
#vjs_video_3 {
  height: 100%;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.short--line {
  width: 50px;
  height: 2px;
  background: #f26726;
}

#background {
  background-color: #ff5c00;
}

#background-container {
  /*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=ff5d00&c2=ff7f27&gt=l&gd=dcl
*/
  background: #ff5d00;
  background: linear-gradient(90deg, #ff5d00, #ff7f27);

  border-top-right-radius: 150px; /* กำหนดรัศมีของมุมบนขวา */
  border-bottom-right-radius: 0px; /* กำหนดรัศมีของมุมล่างขวา */
  padding: 10px 0px 10px 10px;
}

.play-radio-button {
  width: 80px;
  height: 80px;
  border: 3px solid white !important;
  border-radius: 100px !important;
  background: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
  margin-top: 10px;
}

.play-radio-button:active {
  transform: translateY(4px) !important;
}

.play-radio-button > .icon-play {
  font-size: 40px;
  margin-left: 3px;
}

.play-radio-button > .icon-pause {
  font-size: 40px;
}

.marquee {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, .1); */
  /* border: 1px solid rgba(0, 0, 0, .15); */
  overflow: hidden;
  position: relative;
  height: 29px;
  width: 90%;
  /* Full width of its parent */
  white-space: nowrap;
}

.marquee p {
  position: absolute;
  width: auto;
  /* height: 100%; */
  /* margin: 0; */
  line-height: 40px;
  text-align: center;
  /* Start off the right edge */
  transform: translateX(100%);
  /* Animate to the left */
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

hr.style14 {
  padding: 0px;
  width: 90%;
  margin-left: 0px;
  background-color: #fff;
  border-top: 2px dashed #f65c03;
}

.all-button {
  margin-top: 10px;
}

@media screen and (max-width: 400px) {
  .all-button {
    margin-top: 5px;
    margin-right: 30px;
  }

}

@media screen and (max-width: 500px) {
  .all-button {
    margin-top: 5px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 600px) {
  .all-button {
    margin-top: 5px;
  }
}


@media screen and (max-width: 820px) {

  .no-order {
    line-height: 55px;
  }
}

#logo-mini {
  -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
</style>
