<template>
<div>
  <b-container class="">
    <div class="section-title">
      <b-link class="title-link">New Release</b-link>
    </div>

    <b-row class="podcast-list">
      <b-col md="5"  class=" w-100 bg-100 pointer" @click="goto(`/media/${podcast._id}`)" ref="box" :style="{ backgroundImage: `url(${root_url}/${podcast.thumbnail})` }">
				<div :style="`height: ${h}px`" >
				</div>

				<div class="clearfix"></div>
      </b-col>
      <b-col md="7">

				<div class="d-md-none mt-3"></div>

				<b-card @click="goto(`/blog/${item._id}`)" no-body class="overflow-hidden pointer" v-for="item in blogs" :key="item._id" style="height: 33.33%; position: relative">
					<b-row no-gutters>
						<b-col cols="3" >
              <figure class="podcast-image w-100 border blog-img" :style="{ backgroundImage: `url(${root_url}${item.image})`, height: '33.33%' }" />
						</b-col>
						<b-col cols="9">
							<b-card-body :title="item.title" >

								<b-card-text>
                  <b-badge pill variant="info" style="font-size: 14px">{{item.type == 'hot_issue' ? 'Hot Issue' : 'Think Studio'}}</b-badge>
                  <br /> <br>
                  <div class="date_blog">{{ item.created_at}}</div>
								</b-card-text>
							</b-card-body>
						</b-col>
					</b-row>
				</b-card>
          
      </b-col>
   
    </b-row>

    <!-- Explore -->
    <div class="text-center">
      <!-- <b-button variant="primary" size="lg">Explore trending playlists</b-button> -->
    </div>
  </b-container>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      root_url: process.env.VUE_APP_DOMAIN_API + 'images/',
      podcast: {
        banner: "",
        category: "",
        created_at: "",
        deleted: false,
        link: "",
        name: "",
        thumbnail: "",
        type: "",
      },
      h: 350,
      blogs: []
    }
  },
  methods: {
    ...mapActions('website', ['getNewRelease']),
    goto ( link ) {
      this.$router.push(link);
    },
    getWH () {
      let w = this.$refs.box.clientWidth;

      this.h = w;
    }
  },
  async mounted () {

    let res = await this.getNewRelease();

    if (res.success) {

      this.podcast = res.data.podcast;
      this.blogs = res.data.blogs;
      
    }


    this.getWH();


  }

}
</script>

<style >
.vhl-item {
  width: 200px !important;
}
.vhl-btn-left, .vhl-btn-right {
  margin-top: -80px;
}

.vhl-list {
  /* direction: rtl; */
}
</style>
<style scoped>
.blog-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100%;
}
.bg-100 {
  background-size: 100% 100%;
}
.date_blog {
  position: absolute;
  right: 10px;
  bottom: 25px;
}
</style>