
<template>
  <div class="my-5 pb-5" >

    <div style="margin-top: 70px !important" class="bg-danger">
    </div>

    <PartnerView />
    <div style="background: #d9d9d9">
      <b-container>
        <b-row class="pb-5">
          <b-col cols="12" class="pt-5 pb-3 text-center">
            <h2>Contact Us</h2>
          </b-col>
          <b-col md="6" offset-md="3" class="mb-4">
            <div class="text-center" style="color: #8d8d8d">
              ถ้าคุณพร้อม เราก็พร้อมพาคุณก้าว... ก้าวไปสู่ความสำเร็จอย่างยั่งยืน
            </div>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="4" offset-md="4" class="">
            <div class="text-center" v-for="s in sales" :key="s._id">
              {{ s.name }} : <a :href="`tel:${s.phone}`">{{s.phone}}</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <b-container>
        <b-row class="pb-5 pt-5">
          <b-col md="12">
            <br> <br>
          </b-col>

          <b-col md="8" offset-md="2">
            <div class="text-center pt-5">
              <h3>About Us</h3> <br>
              <div class="mb-5" style="max-width: 400px;margin: 0px auto">
                <u>What is … Thinking Channels</u>
              </div>

              <div class="text-left pl-5 pr-5">


                <b>“One For All”</b><br>
                Community ที่เสิร์ฟทุก Content จาก ทุก Platforms  ภายใต้การดูแลของ คลื่นความคิด 96.5 F.M. และ Partner ของเรา มาไว้บน Website เดียวกัน เพื่อให้คุณเข้าถึงได้ง่ายครบวงจร ตอบโจทย์ทุก Lifestyle ของคุณ<br><br>

                <b>Radio</b> <br>
                คลื่นความคิด 96.5 หนึ่งในคลื่นวิทยุชั้นนำของประเทศ ที่นำเสนอเรื่องราวเจาะลึกประเด็นร้อน เกาะติดประเด็นดัง พร้อมการวิเคราะห์สถานการณ์แบบละเอียด โดยทีม Influencer ชั้นนำของประเทศ <br><br>

                <b>PodCast</b> <br>
                สื่อ ดิจิทัลเสียง ที่นำไฮไลท์สำคัญจากรายการวิทยุมาต่อยอด และ Content ใหม่ที่ตอบโจทย์ Lifestyle ของคุณ<br><br>

                <b>Think Studio</b> <br>
                ศูนย์รวมข่าวสารกิจกรรม และการบริการ ของเรา และ Partner ที่พร้อมให้คุณได้เลือกชม พร้อมลงทะเบียนเข้าร่วมงาน<br><br>

                <b>Hot Issue</b> <br>
                สาระความรู้ ความบันเทิง และ Lifestyle ที่พร้อมเสิร์ฟให้คุณในรูปแบบบทความ ผสมกับสื่ออื่นๆ  <br><br>

                <b>Shopping</b> <br>
                หลากหลายสินค้าจาก Partner ของเรา และสินค้าของ ผู้ประกอบการใน ตลาดนัด 96.5 ที่มาให้คุณเลือกชม เลือกช้อป ได้อย่างจุใจ <br> <br>

                ทั้งหมดนี้คือการให้บริการของ  Thinking Channels ของเรา <br>
                และเป้าหมายที่เราคาดหวังมากที่สุดคือ <br>

                <div class=" mt-5">“All For One” <br>
                ทุกบริการของเราสามารถสร้างคุณค่าความสำเร็จให้กับ… “คุณ” <br>
                </div>


              </div>
            </div>

          </b-col>
          <!-- <b-col md="4">
            <b-img-lazy v-bind="mainProps" :src="imgUrl(85)" alt="Image 1"></b-img-lazy>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

import PartnerView from '../Partner/partner.vue'

export default {
  data () {
    return {
      partners: [],
      sales: [],
      url: process.env.VUE_APP_DOMAIN_IMAGE,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        width: 400,
        height: 400,
        class: 'my-3'
      }
    }
  },
  components: {
    PartnerView
  },
  methods: {
    ...mapActions('website', ['getPartner', 'getSales']),
    getImageUrl(imageId) {
      const { width, height } = this.mainProps
      return `https://picsum.photos/${width}/${height}/?image=${imageId}`
    },
    imgUrl (imageId) {
      return `https://picsum.photos/400/300/?image=${imageId}`
    },
    async getData () {
      let res = await this.getPartner();

      console.log( res);

      if (res.success) {
        this.partners = res.data;
      }

    },
    async getAllSales () {
      let res = await this.getSales();

      console.log( res);

      if (res.success) {
        this.sales = res.data;
      }

    }
  },
  async mounted () {
     this.getData();
     this.getAllSales();
  }
}
</script>
<style>
a{
  color: inherit;
  text-decoration: none;
}</style>
