<template>
  <div class="space--mobile">
    
    <b-carousel
      
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide v-for="(item, index) in banners" :key="index">
        
        <template v-slot:img>
            <img
              @click="goto(item.link)"
              class="d-block carou-w pointer"
              width="100%"
              :src="root_url+item.image"
              alt="image slot">
          </template>
      </b-carousel-slide>

    </b-carousel>
  </div>
</template>

<script>

export default {
  data() {
    return {
      root_url: process.env.VUE_APP_DOMAIN_API+'images/',
      slides: [ 
        require('@/assets/images/site/banner5.jpg'), 
        require('@/assets/images/site/banner2.jpg'), 
        require('@/assets/images/site/banner3.jpg'), 
        require('@/assets/images/site/banner4.jpg'), 
        require('@/assets/images/site/banner1.jpg'), 
        // require('@/assets/images/site/jumbotron/image-03.jpg'),
        // require('@/assets/images/site/jumbotron/image-01.jpg'),  
      ],
      slide: 0,
      sliding: null
    };
  },
  methods: {
    goto (link) {
      window.open(link, '_blank');
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    }
  },
  props: {
    banners: Array,
  }
};
</script>

<style scoped>
.carou-w{
  /* height:100vh; */
}

@media only screen and (max-width: 900px) {
  .carou-w{
    height: auto;
  }
  .space--mobile {
    margin-top: 71px;
  }
}
</style>
