
<template>
  <div class="my-5 pb-5" >

    <div style="margin-top: 70px !important" class="bg-danger">
    </div>

    <b-container>
      <b-row>
        <b-col class="mt-3">

          <h1>{{blog.title}}</h1>
          <div>
            {{ blog.created_at }}
          </div>
         <div class="mt-2">
          <audio v-if="blog.sound" controls >
  <source  :src="blog.sound" type="audio/wav"> </audio>
         </div>



          
        </b-col>
  

        <div class="img--100 mt-5 " v-html="blog.content">
          </div>
      </b-row>
    </b-container>

  </div>
</template>
    

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      imageList: [],
      blog: {

      }
    }
  },
  methods: {
    ...mapActions('website', ['viewBlog']),
    showCate (cate) {
      for (let i =0;i<this.categories.length;i++) {
        if ( this.categories[this.blog.type][i].id == cate) {
          return this.categories[this.blog.type][i].name
        }
      }
    },
    
  },
  async mounted() {
    let res = await this.viewBlog(this.$route.params.id);

    if (res.success ){
      this.blog = res.data;
    }

  },
  computed: {
    ...mapState('website', ['categories'])
  }
}
</script>
<style>
.img--100 img{
  width: 50%;
  margin-left: 25%;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 1px solid gainsboro;
}
</style>