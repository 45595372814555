

<template>
  <div class="pt-5 pb-5">
    <b-container class="mb-5">
      <b-row class="justify-content-md-center pb-5 pt-5 mb-5">
        <b-col md="12" class="mb-5">
          <h1>Partner</h1>
        </b-col>
        
        <b-col md="12">
          <infinite-slide-bar :barStyle="{ padding: '5px 0' }" duration="20s">
            <img width="150" height="150" class="mr-4" v-for="item in partners" :key="item._id" v-bind="mainProps" :src="url+'/'+item.image" :alt="item.title" />
          </infinite-slide-bar>
        </b-col>


      </b-row>
    </b-container>
  </div>
</template>


<script>

import InfiniteSlideBar from 'vue-infinite-slide-bar'
import { mapActions } from 'vuex'

export default {
  components: {
    InfiniteSlideBar
  },
  data () {
    return {
      partners: [],
      url: process.env.VUE_APP_DOMAIN_IMAGE,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        width: 400,
        height: 400,
        class: 'my-3'
      }
    }
  },
  methods: {
    ...mapActions('website', ['getPartner']),
    getImageUrl(imageId) {
      const { width, height } = this.mainProps
      return `https://picsum.photos/${width}/${height}/?image=${imageId}`
    },
   
    async getData () {
      let res = await this.getPartner();

      if (res.success) {
        this.partners = res.data;
      }

    }
  },
  async mounted () {
    await this.getData();
  }
}
</script>
    