<template>
  <div>
    <Jumbotron :banners="banners" />

    <!-- Video Player -->
    <VideoPlayer />

    <!-- News -->
    <NewRelease style="background: #f9f9f9;
    padding-top: 80px;
    padding-bottom: 100px;" :podcasts="podcasts" />


    <Partner />

    <!-- Podcast -->
    <Podcast :podcasts="podcasts" />

    <!-- <HotIssue :podcasts="hot_issue" /> -->

    <ThinkStudio :podcasts="think_studio" />

    <Product style="background: whitesmoke" v-if="products.length > 0" :products="products" />

  </div>
</template>

<script>
import VideoPlayer from '@/views/HomePage/VideoPlayer'
import NewRelease from '@/views/HomePage/NewRelease'
import Jumbotron from '@/views/HomePage/Jumbotron'
import Podcast from '@/views/HomePage/Podcast'
import News from '@/views/HomePage/News'
import ThankyouJoin from '@/views/HomePage/ThankyouJoin'
import HotIssue from '@/views/HomePage/HotIssue.vue'
import ThinkStudio from '@/views/HomePage/ThinkStudio.vue'
import Product from '@/views/Product'

import Partner from '@/views/HomePage/partner.vue'

import { mapActions } from 'vuex'

export default {
  data () {
    return {
      banners: [],
      podcasts: [],
      hot_issue: [],
      think_studio: [],
      previews: {
        podcast: [],
        clip: [],
        movie: [],
        partner: []
      },
      products: []
    }
  },
  components: {
    VideoPlayer,
    NewRelease,
    HotIssue,
    ThinkStudio,
    Jumbotron,
    Podcast,
    News,
    ThankyouJoin,
    Product,
    Partner
  },
  methods: {
    ...mapActions('website', ['getHomepage', 'getBlogHomepage'])
  },
  async mounted () {
    let res = await this.getHomepage();

    if ( res.success ) {
      this.banners = res.data.banners;
      this.podcasts = res.data.podcasts;
      this.products = res.data.products;
    }

    let _this = this;

    setTimeout( async () => {

      let blog = await _this.getBlogHomepage();

      if ( blog.success ) {

        _this.hot_issue = blog.data.hot_issue;
      _this.think_studio = blog.data.think_studio;

      }

    }, 500);



  }
}
</script>
