<template>
  <div class="news-container py-5">
    <b-container >

      <b-row v-if="blogs.length> 0">

        <b-col sm="6" lg="3" class="p-5" 
          v-for="(item, index) in blogs" :key="index"
        >
          <div class="section-title">
            <div class="title-link mb-3">{{ item._id }}</div>
          </div>

          <Carousel :carousel="item" />
        </b-col>

        <b-col md="1" ></b-col>
        
        <!-- <b-col
          v-for="item in blogs"
          :key="item.index"
          lg="3"
          class="mb-5"
        >

        
          <div class="section-title">
            <div class="title-link mb-3">{{item.type}}</div>
          </div>
          <b-card class="card-news" no-body>
            <figure>
              <b-link to="/news_details" class="news-img" :style="{ backgroundImage: `url(${item.imgName})` }" />
            </figure>
            <b-card-body>
              <b-link to="/news_details" class="card-title">{{ item.title }}</b-link>

              <div class="news-ref">
                <span><strong>{{ item.author }}</strong><time>{{ item.time }}</time></span>
                <div>
                  <b-link class="favorite"><i class="far fa-heart fa-lg" /></b-link>
                  <b-link class="share"><i class="far fa-share-alt fa-lg" /></b-link>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import Carousel from '@/components/Carousel'

export default {
  components: {
    Carousel
  },
  data () {
    return {
      blogs: [
        { _id: "clip", media: []},
        { _id: "clip", media: []},
        { _id: "clip", media: []},
        { _id: "clip", media: []}
      ]
    }
  },
  methods: {
    ...mapActions('website', ['getPreview']),
    imgUrl (img) {
      return require(`@/assets/images/homepage/blog/${img}.jpg`)
    }
  },
  async beforeMount () {
    let res = await this.getPreview();

    if ( res.success ) {
      this.blogs = res.data;
    }
  },
  async mounted () {
    

  }
}


</script>
